// eslint-disable-next-line import/no-cycle
import { AxiosInstance, AxiosResponse } from 'axios';
import SortFilter from '@/enums/SortFilter';
import axios from '@/plugins/axios';
import { WorkingHoursTransaction } from '@/types/WorkingHoursTransaction';
import ResponseHelper from '@/enums/ResponseHelper';

class BaseService {
  baseURL = '';

  constructor(subURL = '', responseHelper?: ResponseHelper) {
    this.baseURL = `${process.env.VUE_APP_BASE_URL}${subURL}`;
    if (
      responseHelper &&
      ResponseHelper.IncludeResponseInCatchBlock === responseHelper
    ) {
      this.makeResponseInCatchBlockAvailable(axios);
    }
  }

  async get(params = ''): Promise<any[] | any> {
    const res = await axios.get(`${this.baseURL}?${params}`);
    return res.data;
  }

  async getBlob(params = ''): Promise<any[] | any> {
    const res = await axios.get(`${this.baseURL}?${params}`, {
      responseType: 'blob',
    });
    return res.data;
  }

  async getOne(id, params = ''): Promise<any> {
    const res = await axios.get(`${this.baseURL}/${id}?${params}`);
    return res.data;
  }

  async getSubresource(id: number, subresource: string): Promise<any> {
    const res = await axios.get(`${this.baseURL}/${id}/${subresource}`);
    return res.data;
  }

  async post(payload: {}): Promise<any> {
    const res = await axios.post(`${this.baseURL}`, payload);
    return res.data;
  }

  async postWithWholeResult(payload: {}): Promise<AxiosResponse> {
    // Not able to override interceptor for retrieving response instead of error msg!
    // axios.interceptors.response.use(
    //   (response) => response,
    //   async (error) => {
    //     return Promise.reject(error);
    //   }
    // );
    return await axios.post(`${this.baseURL}`, payload);
  }

  async upload(payload: {}): Promise<any> {
    const res = await axios.post(`${this.baseURL}`, payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return res;
  }

  async put(id, payload: {}): Promise<any> {
    const res = await axios.put(`${this.baseURL}/${id || ''}`, payload);
    return res.data;
  }

  async postSubresource(
    id: number | string,
    subresource: string,
    payload: any
  ): Promise<any> {
    const res = await axios.post(
      `${this.baseURL}/${id}/${subresource}`,
      payload
    );
    return res.data;
  }

  async putSubresource(
    id: number | string,
    subresource: string,
    payload: any
  ): Promise<any> {
    const res = await axios.put(
      `${this.baseURL}/${id}/${subresource}`,
      payload
    );
    return res.data;
  }

  async putProcess(id): Promise<any> {
    const res = await axios.put(`${this.baseURL}/${id}`);
    return res.data;
  }

  makeResponseInCatchBlockAvailable(axios: AxiosInstance) {
    if ((axios.interceptors.response as any).handlers.length == 0) {
      console.log('Register Interceptor');
      axios.interceptors.response.use(
        (response) => response,
        async (error) => {
          console.log('makeResponseInCatchBlockAvailable', error.response);
          return Promise.reject(error.response);
        }
      );
    } else {
      console.log('Already registered Interceptor');
    }
  }

  async delete(id: number): Promise<any> {
    const res = await axios.delete(`${this.baseURL}/${id}`);
    return res.data;
  }

  async deletePayload(payload: any): Promise<any> {
    const res = await axios.delete(`${this.baseURL}`, { data: payload });
    return res.data;
  }

  async putString(resourceId: string, payload: {}): Promise<any> {
    const res = await axios.put(`${this.baseURL}/${resourceId}`, payload);
    return res.data;
  }

  async putPayload(payload: {}): Promise<any> {
    const res = await axios.put(`${this.baseURL}`, payload);
    return res.data;
  }

  async deleteMultiple(params = ''): Promise<any> {
    const res = await axios.delete(`${this.baseURL}?${params}`);
    return res.data;
  }

  appendSortParams(sortBy: string, orderBy: SortFilter): string {
    return `sortBy=${sortBy}&orderBy=${orderBy}`;
  }
}

export default BaseService;
