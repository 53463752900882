/* eslint-disable no-param-reassign */
import Vue, { VueConstructor } from 'vue';
import i18n from '@/i18n';

export const setTitle = (title) => {
  document.title = `Werkules - ${title}`;
};

export const currency = (value) => {
  const formatter = new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR',
  });
  const parsedValue = Number(Number.parseFloat(value).toFixed(2));
  return formatter.format(value ? parsedValue : 0);
};

export const currencyToNumber = (value) => {
  let adjustedValue = value;
  if (typeof value === 'string' && !value.includes(',')) {
    adjustedValue = `${value},`;
  }

  const formatter = `${adjustedValue
    .toString()
    .slice(0, adjustedValue.toString().lastIndexOf(','))
    .toString()
    .replaceAll('.', '')} .${adjustedValue
    .toString()
    .slice(adjustedValue.toString().lastIndexOf(','))
    .substr(1, 2)}`;
  return adjustedValue
    ? parseFloat(formatter.replace(' ', '').replace(',', '.'))
    : 0;
};

export const percentageToNumber = (value) => {
  const formatter = value
    .toString()
    .slice(0, value.toString().lastIndexOf(','))
    .toString();

  return value ? parseFloat(formatter) : 0;
};

export const stringToCurrency = (value) => {
  if (value == null) {
    return '0 €';
  }
  let removedThousandPoints = value.toString();
  if (value.toString().includes('.') && value.toString().includes(',')) {
    removedThousandPoints = value.toString().replaceAll('.', '');
  }
  let cleanedValue = removedThousandPoints.replace(',', '.').replace('€', '');
  return Number(cleanedValue).toLocaleString('de-DE', {
    style: 'currency',
    currency: 'EUR',
  });
};

export const getFileName = (url) => {
  if (url) {
    return url.substring(url.lastIndexOf('/') + 1);
  }
  return '';
};

// eslint-disable-next-line consistent-return
export const concatFileName = (files) => {
  if (files !== undefined) {
    if (files.length > 2) {
      return `${getFileName(files[0].url)},
        ${getFileName(files[1].url)}, ...,and ${files.length - 2} more`;
    }
    let result = '';
    for (let i = 0; i < files.length; i += 1) {
      if (i !== 0) {
        result += ', ';
      }
      result += getFileName(files[i].url);
    }
    return result;
  }
};

export const roundingNumber = (number, scale) => {
  const factor = Math.pow(10, scale);
  const tempNumber = number * factor;
  const roundedTempNumber = Math.round(tempNumber);
  return roundedTempNumber / factor;
};

export const getDayName = (dayname) => {
  switch (dayname) {
    case 'Monday':
      return `${i18n.t('days.monday')}`;
    case 'Tuesday':
      return `${i18n.t('days.tuesday')}`;
    case 'Wednesday':
      return `${i18n.t('days.wednesday')}`;
    case 'Thursday':
      return `${i18n.t('days.thursday')}`;
    case 'Friday':
      return `${i18n.t('days.friday')}`;
    case 'Saturday':
      return `${i18n.t('days.saturday')}`;
    case 'Sunday':
      return `${i18n.t('days.sunday')}`;
    default:
      return '';
  }
};
declare module 'vue/types/vue' {
  interface Vue {
    $setTitle: typeof setTitle;
    $currency: typeof currency;
    $roundingNumber: typeof roundingNumber;
  }
}

Vue.use((vue: VueConstructor) => {
  vue.prototype.$setTitle = setTitle;
  vue.prototype.$currency = currency;
  vue.prototype.$roundingNumber = roundingNumber;
});

export default {
  setTitle,
  currency,
  roundingNumber,
};
