import { RouteConfig } from 'vue-router';
import i18n from '@/i18n';

const baseRoute = 'finance';

const routes: Array<RouteConfig> = [
  {
    path: `${baseRoute}/costs`,
    name: `${i18n.t('modules.finances.cost.name')}`,
    component: () =>
      import(/* webpackChunkName: "cost" */ '@/views/finances/costs/Index.vue'),
  },
];

export default routes;
