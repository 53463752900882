export enum ModuleName {
  PROJECT_ASSIGNMENT_ALL = 'projectAssignmentAll',
  PROJECT_ASSIGNMENT_EXECUTION = 'projectAssignmentExecution',
  PROJECT_ASSIGNMENT_FINISHED = 'projectAssignmentFinished',

  PROJECT_OFFER_OPEN = 'projectOfferOpen',
  PROJECT_OFFER_OVERDUE = 'projectOfferOverdue',
  PROJECT_OFFER_ACCEPTED = 'projectOfferAccepted',
  PROJECT_OFFER_DECLINED = 'projectOfferDeclined',

  PROJECT_PRE_PERFORMANCE_EXECUTION = 'projectPrePerformanceExecution',
  PROJECT_PRE_PERFORMANCE_FINISHED = 'projectPrePerformanceFinished',

  PROJECT_TASK = 'projectTask',

  FINANCE_INCOMING_INVOICE_RELEASED = 'financeIncomingInvoiceReleased',
  FINANCE_INCOMING_INVOICE_APPROVED = 'financeIncomingInvoiceApproved',
  FINANCE_INCOMING_INVOICE_PAID = 'financeIncomingInvoicePaid',

  FINANCE_OUTGOING_INVOICE = 'financeOutgoingInvoice',

  FINANCE_OUTGOING_VALUE_CORRECTION = 'financeOutgoingValueCorrection',

  FINANCE_COST_STAFF = 'financeCostStaff',

  FINANCE_COST_SUBCONTRACTOR = 'financeCostSubcontractor',

  FINANCE_COST_MATERIAL = 'financeCostMaterial',

  FINANCE_COST_LOAN = 'financeCostLoan',

  WORKING_HOURS_CONSTRUCTION = 'workingHourConstruction',
  WORKING_HOURS_CONSTRUCTION_OPEN = 'workingHourConstructionOpen',
  WORKING_HOURS_CONSTRUCTION_ACCEPTED = 'workingHourConstructionAccepted',
  WORKING_HOURS_CONSTRUCTION_REJECTED = 'workingHourConstructionRejected',

  WORKING_HOURS_EDUCATION = 'workingHourEducation',
  WORKING_HOURS_EDUCATION_OPEN = 'workingHourEducationOpen',
  WORKING_HOURS_EDUCATION_ACCEPTED = 'workingHourEducationAccepted',
  WORKING_HOURS_EDUCATION_REJECTED = 'workingHourEducationRejected',

  WORKING_HOURS_OFFICE = 'workingHourOffice',
  WORKING_HOURS_OFFICE_OPEN = 'workingHourOfficeOpen',
  WORKING_HOURS_OFFICE_ACCEPTED = 'workingHourOfficeAccepted',
  WORKING_HOURS_OFFICE_REJECTED = 'workingHourOfficeRejected',

  WORKING_HOURS_SICK_OPEN = 'workingHourSickLeaveOpen',
  WORKING_HOURS_SICK_ACCEPTED = 'workingHourSickLeaveAccepted',
  WORKING_HOURS_SICK_REJECTED = 'workingHourSickLeaveRejected',

  WORKING_HOURS_VACATION_OPEN = 'workingHourVacationOpen',
  WORKING_HOURS_VACATION_ACCEPTED = 'workingHourVacationAccepted',
  WORKING_HOURS_VACATION_REJECTED = 'workingHourVacationRejected',

  EMPLOYEE_CONSTRUCTION_WORKER = 'conSiteUser',

  EMPLOYEE_OFFICE_WORKER = 'officeUser',

  CLIENT_PERSONAL = 'contactClientPersonal',

  CLIENT_COMPANY = 'contactClientCompany',

  FINANCE_INVOICE_TO_ISSUE = 'financeInvoiceToIssue',
}
