import { RouteConfig } from 'vue-router';
import i18n from '@/i18n';
import architectEdit from '@/router/contacts/architects/architect-edit';
import architectDetail from '@/router/contacts/architects/architect-detail';

const baseRoute = 'contact/architects';

const routes: Array<RouteConfig> = [
  {
    name: `${i18n.t('modules.contact.architects.name')}`,
    path: baseRoute,
    component: () =>
      import(
        /* webpackChunkName: "architects-list" */ '@/views/contacts/architects/Index.vue'
      ),
  },
  ...architectEdit,
  ...architectDetail,
];

export default routes;
