import { RouteConfig } from 'vue-router';
import i18n from '@/i18n';

const baseRoute = 'settings';

const router: Array<RouteConfig> = [
  {
    path: `${baseRoute}/roles`,
    name: `${i18n.t('modules.settings.rolePermission')}`,
    component: () =>
      import(
        /* webpackChunkName: "settings" */ '@/views/settings/roles/Index.vue'
      ),
  },
  {
    path: `${baseRoute}/holidays`,
    name: `${i18n.t('modules.settings.holidays.name')}`,
    component: () =>
      import(
        /* webpackChunkName: "settings" */ '@/views/settings/holidays/Index.vue'
      ),
  },
  {
    name: `${i18n.t('modules.settings.crafts.name')}`,
    path: `${baseRoute}/crafts`,
    component: () =>
      import(
        /* webpackChunkName: "settings" */ '@/views/settings/crafts/Index.vue'
      ),
  },
  {
    path: `${baseRoute}/employee-types`,
    name: `${i18n.t('modules.settings.employeeTypes.name')}`,
    component: () =>
      import(
        /* webpackChunkName: "settings" */ '@/views/settings/employeeTypes/Index.vue'
      ),
  },
  {
    path: `${baseRoute}/working-hours`,
    name: `${i18n.t('modules.settings.workingHours.name')}`,
    component: () =>
      import(
        /* webpackChunkName: "settings" */ '@/views/settings/working-hours/Index.vue'
      ),
  },
  {
    path: `${baseRoute}/company-details`,
    name: `${i18n.t('modules.settings.companyData.name')}`,
    component: () =>
      import(
        /* webpackChunkName: "settings" */ '@/views/settings/company-details/Index.vue'
      ),
  },
];

export default router;
