import { RouteConfig } from 'vue-router';
import i18n from '@/i18n';

const routes: Array<RouteConfig> = [
  {
    path: 'personal/export',
    name: `${i18n.t('modules.exportPersonal.name')}`,
    component: () =>
      import(
        /* webpackChunkName: "exportPersonal" */ '@/views/personal/export/Index.vue'
      ),
  },
];

export default routes;
