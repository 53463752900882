import { RouteConfig } from 'vue-router';
import architects from '@/router/contacts/architects/index';
import clients from '@/router/contacts/clients/index';
import subcontractors from '@/router/contacts/subcontractors/index';
import suppliers from '@/router/contacts/suppliers/index';

const routes: Array<RouteConfig> = [
  ...architects,
  ...clients,
  ...subcontractors,
  ...suppliers,
];

export default routes;
