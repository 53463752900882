import { RouteConfig } from 'vue-router';

const baseRoute = 'users';

const routes: Array<RouteConfig> = [
  {
    path: `${baseRoute}/:userId/detail`,
    component: () =>
      import(/* webpackChunkName: "users" */ '@/views/users/Detail.vue'),
    children: [
      {
        name: 'Personal Information - Detail',
        path: '',
        component: () =>
          import(
            /* webpackChunkName: "users" */ '@/components/moduleSpecific/employee/tabsDetail/PersonalInformation.vue'
          ),
      },
      {
        name: 'Employment - Detail',
        path: 'employment-details',
        component: () =>
          import(
            /* webpackChunkName: "users" */ '@/components/moduleSpecific/employee/tabsDetail/Employment.vue'
          ),
      },
      {
        name: 'Tax Data - Detail',
        path: 'tax-details',
        component: () =>
          import(
            /* webpackChunkName: "users" */ '@/components/moduleSpecific/employee/tabsDetail/TaxData.vue'
          ),
      },
      {
        name: 'Social Insurance - Detail',
        path: 'social-insurances',
        component: () =>
          import(
            /* webpackChunkName: "users" */ '@/components/moduleSpecific/employee/tabsDetail/SocialInsurance.vue'
          ),
      },
      {
        name: 'Document - Detail',
        path: 'documents/:documents',
        component: () =>
          import(
            /* webpackChunkName: "users" */ '@/components/moduleSpecific/employee/tabsDetail/Document.vue'
          ),
      },
      {
        name: 'Authentication - Detail',
        path: 'authentication',
        component: () =>
          import(
            /* webpackChunkName: "users" */ '@/components/moduleSpecific/employee/tabsDetail/Authentication.vue'
          ),
      },
      {
        name: 'Permissions - Detail',
        path: 'permissions/:permissions',
        component: () =>
          import(
            /* webpackChunkName: "users-edit" */ '@/components/moduleSpecific/employee/tabsDetail/Permissions.vue'
          ),
      },
      {
        name: 'Dashboard - Detail',
        path: 'dashboard',
        component: () =>
          import(
            /* webpackChunkName: "users" */ '@/components/moduleSpecific/employee/tabsDetail/Dashboard.vue'
          ),
      },
      {
        name: 'Others - Detail',
        path: 'others',
        component: () =>
          import(
            /* webpackChunkName: "users" */ '@/components/moduleSpecific/employee/tabsDetail/Others.vue'
          ),
      },
    ],
  },
];

export default routes;
