interface LeaveModule {
  refreshLeave: boolean;
}

const data = (): LeaveModule => ({
  refreshLeave: false,
});

const getters = {
  refreshLeave: (state: LeaveModule) => state.refreshLeave,
};

const mutations = {
  setRefreshLeave: (state: LeaveModule, logic: boolean) => {
    state.refreshLeave = logic;
  },
};

export default {
  state: data,
  getters,
  mutations,
};
