import { RouteConfig } from 'vue-router';
import i18n from '@/i18n';

const baseRoute = 'business';

const routes: Array<RouteConfig> = [
  {
    path: `${baseRoute}/assignments`,
    name: `${i18n.t('modules.business.assignments.name')}`,
    component: () =>
      import(
        /* webpackChunkName: "assignments-list" */ '@/views/business/assignments/Index.vue'
      ),
  },
  {
    path: `${baseRoute}/assignments/:projectNumber/edit-photo`,
    name: 'Edit Photo',
    component: () =>
      import(
        /* webpackChunkName: "assignments-edit" */ '@/components/moduleSpecific/assignment/tabs-assignment/EditPhoto.vue'
      ),
    props: true,
  },
  {
    path: `${baseRoute}/assignments/:projectNumber/create-report`,
    name: 'Create Report',
    component: () =>
      import(
        /* webpackChunkName: "assignments-edit" */ '@/components/moduleSpecific/assignment/tabs-assignment/reports/CreateReport.vue'
      ),
    props: true,
  },
  {
    path: `${baseRoute}/assignments/:projectNumber/edit`,
    component: () =>
      import(
        /* webpackChunkName: "assignments-edit" */ '@/views/business/assignments/Edit.vue'
      ),
    children: [
      {
        name: 'Detail',
        path: '',
        component: () =>
          import(
            /* webpackChunkName: "assignments-edit" */ '@/components/moduleSpecific/assignment/tabs-assignment/Detail.vue'
          ),
      },
      {
        name: 'Invoices',
        path: 'invoices',
        component: () =>
          import(
            /* webpackChunkName: "assignments-edit" */ '@/components/moduleSpecific/assignment/tabs-assignment/Invoices.vue'
          ),
      },
      {
        name: 'People',
        path: 'people',
        component: () =>
          import(
            /* webpackChunkName: "assignments-edit" */ '@/components/moduleSpecific/assignment/tabs-assignment/People.vue'
          ),
      },
      {
        name: 'Checklist',
        path: 'checklist',
        component: () =>
          import(
            /* webpackChunkName: "assignments-edit" */ '@/components/moduleSpecific/assignment/tabs-assignment/Checklist.vue'
          ),
      },
      {
        name: 'Document',
        path: 'document',
        component: () =>
          import(
            /* webpackChunkName: "assignments-edit" */ '@/components/moduleSpecific/assignment/tabs-assignment/Document.vue'
          ),
      },
      {
        name: 'Photos',
        path: 'photos',
        component: () =>
          import(
            /* webpackChunkName: "assignments-edit" */ '@/components/moduleSpecific/assignment/tabs-assignment/Photos.vue'
          ),
      },
      {
        name: 'Remarks',
        path: 'remarks',
        component: () =>
          import(
            /* webpackChunkName: "assignments-edit" */ '@/components/moduleSpecific/assignment/tabs-assignment/Remarks.vue'
          ),
      },
      {
        name: 'Report',
        path: 'report',
        component: () =>
          import(
            /* webpackChunkName: "assignments-edit" */ '@/components/moduleSpecific/assignment/tabs-assignment/reports/Report.vue'
          ),
      },
      {
        name: 'Rapport',
        path: 'rapport',
        component: () =>
          import(
            /* webpackChunkName: "assignments-edit" */ '@/components/moduleSpecific/assignment/tabs-assignment/reports/Rapport.vue'
          ),
      },
    ],
  },
];

export default routes;
