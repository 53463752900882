import { RouteConfig } from 'vue-router';

const baseRoute = 'contact/suppliers';

const routes: Array<RouteConfig> = [
  {
    path: `${baseRoute}/:id`,
    component: () =>
      import(
        /* webpackChunkName: "suppliers" */ '@/views/contacts/supplier/Edit.vue'
      ),
    children: [
      {
        name: 'Supplier Contact',
        path: '',
        component: () =>
          import(
            /* webpackChunkName: "suppliers" */ '@/components/moduleSpecific/supplier/tabs-supplier/Contact.vue'
          ),
      },
      {
        name: 'Supplier Payment Terms',
        path: 'payment-terms',
        component: () =>
          import(
            /* webpackChunkName: "suppliers" */ '@/components/moduleSpecific/supplier/tabs-supplier/PaymentTerms.vue'
          ),
      },
      {
        name: 'Supplier Statistics',
        path: 'statistics/:statistics',
        component: () =>
          import(
            /* webpackChunkName: "suppliers" */ '@/components/moduleSpecific/supplier/tabs-supplier/Statistics.vue'
          ),
      },
      {
        name: 'Supplier Other Details',
        path: 'other-details',
        component: () =>
          import(
            /* webpackChunkName: "suppliers" */ '@/components/moduleSpecific/supplier/tabs-supplier/OtherDetails.vue'
          ),
      },
    ],
  },
];

export default routes;
