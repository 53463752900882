import { RouteConfig } from 'vue-router';
import i18n from '@/i18n';

const baseRoute = 'business';

const routes: Array<RouteConfig> = [
  {
    path: `${baseRoute}/offers`,
    name: `${i18n.t('modules.business.offers.name')}`,
    component: () =>
      import(
        /* webpackChunkName: "offers-list" */ '@/views/business/offers/Index.vue'
      ),
  },
];

export default routes;
