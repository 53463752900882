export enum FilterKey {
  BUSINESS_OFFERS = 'business/offers',
  BUSINESS_ASSIGNMENTS = 'business/assignments',
  PROJECT_TASKS = 'projects/tasks',
  PERSONAL_USERS = 'personal/users',
  CONTACT_ARCHITECTS = 'contact/architects',
  INVENTORIES_TOOLS = 'inventories/tools',
  SETTINGS_EMPLOYEETYPES = 'settings/employeetypes',
  SETTINGS_CRAFTS = 'settings/crafts',
  SETTINGS_CATEGORIES = 'settings/categories',
  SETTINGS_ROLES = 'settings/roles',
  BUSINESS_PREPERFORMANCECHECK = 'business/pre-performance-check',
  FINANCE_INVOICES = 'finance/invoices',
  PERSONAL_WORKINGHOURS = 'personal/workinghours',
  CONTACT_CLIENTS = 'contact/clients',
  CONTACT_SUBCONTRACTORS = 'contact/subcontractors',
  CONTACT_SUPPLIERS = 'contact/suppliers',
  INVENTORIES_VEHICLES = 'inventories/vehicles',
  SETTINGS_HOLIDAYS = 'settings/holidays',
  FINANCE_OPENCLAIMS = '/finance/open-claims',
}

export enum FilterOfTypeKey {
  STORAGE = 'storage',
}

type FilterValueType = string[] | null;
type FilterOfTypeValueType = Record<FilterOfTypeKey, FilterValueType>;
type FilterType = Record<FilterKey, FilterValueType | FilterOfTypeValueType>;

interface FilterModule {
  filters: FilterType;
}

interface SetFilterPayload {
  key: FilterKey;
  values: string[] | null;
}

interface SetFilterOfTypePayload extends SetFilterPayload {
  type: FilterOfTypeKey;
}

const data = (): FilterModule => ({
  filters: {
    [FilterKey.BUSINESS_OFFERS]: null,
    [FilterKey.BUSINESS_ASSIGNMENTS]: null,
    [FilterKey.PROJECT_TASKS]: null,
    [FilterKey.PERSONAL_USERS]: null,
    [FilterKey.CONTACT_ARCHITECTS]: null,
    [FilterKey.INVENTORIES_TOOLS]: null,
    [FilterKey.SETTINGS_EMPLOYEETYPES]: null,
    [FilterKey.SETTINGS_CRAFTS]: null,
    [FilterKey.SETTINGS_CATEGORIES]: null,
    [FilterKey.SETTINGS_ROLES]: null,
    [FilterKey.BUSINESS_PREPERFORMANCECHECK]: null,
    [FilterKey.FINANCE_INVOICES]: null,
    [FilterKey.PERSONAL_WORKINGHOURS]: null,
    [FilterKey.CONTACT_CLIENTS]: null,
    [FilterKey.CONTACT_SUBCONTRACTORS]: null,
    [FilterKey.CONTACT_SUPPLIERS]: null,
    [FilterKey.INVENTORIES_VEHICLES]: null,
    [FilterKey.SETTINGS_HOLIDAYS]: null,
    [FilterKey.FINANCE_OPENCLAIMS]: null,
  },
});

const getters = {
  filters: (state: FilterModule) => state.filters,
  getFilter: (state: FilterModule) => (key: FilterKey) => state.filters[key],
  getFilterOfType:
    (state: FilterModule) => (key: FilterKey, type: FilterOfTypeKey) =>
      state.filters[key] !== null ? state.filters[key]?.[type] : null,
};

const mutations = {
  setFilters: (state: FilterModule, filters: FilterType) => {
    state.filters = filters;
  },

  setFilter: (state: FilterModule, payload: SetFilterPayload) => {
    if (payload.key === undefined || payload.key === null) return;

    state.filters[payload.key] = payload.values;
  },

  setFilterOfType: (state: FilterModule, payload: SetFilterOfTypePayload) => {
    if (payload.key === undefined || payload.key === null) return;

    if (payload.type === undefined || payload.type === null) return;

    if (state.filters[payload.key] === null)
      state.filters[payload.key] = {} as FilterOfTypeValueType;

    (state.filters[payload.key] as FilterOfTypeValueType)[payload.type] =
      payload.values;
  },
};

const actions = {
  setFilters: ({ commit }, filter: FilterType) => {
    commit('setFilters', filter);
  },

  setFilter: ({ commit }, payload: SetFilterPayload) => {
    commit('setFilter', payload);
  },

  setFilterOfType: ({ commit }, payload: SetFilterOfTypePayload) => {
    commit('setFilterOfType', payload);
  },
};

export default {
  state: data,
  getters,
  mutations,
  actions,
};
