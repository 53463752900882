import NProgress from 'nprogress';
import Vue from 'vue';

type SnackbarType = {
  isVisible: boolean;
  message: string;
  color: string;
};

interface GlobalModule {
  snackbar: SnackbarType;
  flags: Record<string, boolean>;
}

const data = (): GlobalModule => ({
  snackbar: {
    isVisible: false,
    message: '',
    color: '',
  },
  flags: {},
});

const getters = {
  snackbar: (state: GlobalModule) => state.snackbar,
  flags: (state: GlobalModule) => state.flags,
};

const actions = {
  setLoading: (ctx, status: boolean) => {
    if (status) {
      NProgress.start();
    } else {
      NProgress.done();
    }
  },
  setSnackbar: ({ commit }, snackbar: SnackbarType) => {
    commit('setSnackbar', snackbar);
  },
  /*
  async fetchFlags({ commit }) {
    await Vue.prototype.$remoteConfig.fetchAndActivate();

    const features = await Vue.prototype.$remoteConfig
      .getValue('dev')
      .asString();
    commit('setFlags', JSON.parse(features));
  },
  */
};

const mutations = {
  setSnackbar: (state: GlobalModule, snackbar: SnackbarType) => {
    state.snackbar = snackbar;
  },
  setFlags: (state: GlobalModule, flags: Record<string, boolean>) => {
    state.flags = flags;
  },
};

export default {
  state: data,
  getters,
  actions,
  mutations,
};
