import { RouteConfig } from 'vue-router';
import i18n from '@/i18n';

const baseRoute = 'finance';

const routes: Array<RouteConfig> = [
  {
    path: `${baseRoute}/invoices`,
    name: `${i18n.t('modules.finances.invoices.name')}`,
    component: () =>
      import(
        /* webpackChunkName: "invoices" */ '@/views/finances/invoices/Index.vue'
      ),
  },
  {
    path: `${baseRoute}/invoices/:id`,
    name: `${i18n.t('modules.finances.invoices.detail')}`,
    component: () =>
      import(
        /* webpackChunkName: "invoices-detail" */ '@/views/finances/invoices/DetailPage.vue'
      ),
  },
];

export default routes;
