import { RouteConfig } from 'vue-router';

const baseRoute = 'contact/subcontractors';

const routes: Array<RouteConfig> = [
  {
    path: `${baseRoute}/:id/edit`,
    component: () =>
      import(
        /* webpackChunkName: "subcon" */ '@/views/contacts/subcontractors/Edit.vue'
      ),
    children: [
      {
        name: 'Subcontractor Contacts',
        path: '',
        component: () =>
          import(
            /* webpackChunkName: "subcon" */ '@/components/moduleSpecific/subcontractor/tabs-subcontractor/Contacts.vue'
          ),
      },
      {
        name: 'Subcontractor Formal Requirement',
        path: 'formal-requirement',
        component: () =>
          import(
            /* webpackChunkName: "subcon-edit" */ '@/components/moduleSpecific/subcontractor/tabs-subcontractor/FormalRequirement.vue'
          ),
      },
      {
        name: 'Subcontractor Payment Terms',
        path: 'payment-terms',
        component: () =>
          import(
            /* webpackChunkName: "subcon-edit" */ '@/components/moduleSpecific/subcontractor/tabs-subcontractor/PaymentTerms.vue'
          ),
      },
      {
        name: 'Subcontractor Other Details',
        path: 'other-details',
        component: () =>
          import(
            /* webpackChunkName: "subcon-edit" */ '@/components/moduleSpecific/subcontractor/tabs-subcontractor/OtherDetails.vue'
          ),
      },
      {
        name: 'Subcontractor App Access',
        path: 'app-access',
        component: () =>
          import(
            /* webpackChunkName: "subcon-edit" */ '@/components/moduleSpecific/subcontractor/tabs-subcontractor/AppAccess.vue'
          ),
      },
    ],
  },
];

export default routes;
