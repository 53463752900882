import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';

import auth from '@/store/modules/auth';
import global from '@/store/modules/global';
import clientContact from '@/store/modules/client';
import table from '@/store/modules/table';
import leave from '@/store/modules/leave';
import search from '@/store/modules/search';
import filter from '@/store/modules/filter';

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  modules: ['filter'],
});

export default new Vuex.Store({
  modules: {
    auth,
    global,
    clientContact,
    table,
    leave,
    search,
    filter,
  },
  state: {
    sidebarDrawer: null,
    customizerDrawer: false,
    sidebarColor: '#c80b0e', // Change Sidebar Color || 'white', | "#2b2b2b" | "rgb(44, 59, 164)" | "rgb(96, 44, 164)" | "rgb(151, 210, 219)" | "rgb(77, 86, 100)"
    SidebarBg: '',
    navbarColor: 'white',
    setHorizontalLayout: false, // Horizontal layout
  },
  mutations: {
    SET_SIDEBAR_DRAWER(state, payload) {
      state.sidebarDrawer = payload;
    },
    SET_CUSTOMIZER_DRAWER(state, payload) {
      state.customizerDrawer = payload;
    },
    SET_SIDEBAR_COLOR(state, payload) {
      state.sidebarColor = payload;
    },
    SET_NAVBAR_COLOR(state, payload) {
      state.navbarColor = payload;
    },
    SET_LAYOUT(state, payload) {
      state.setHorizontalLayout = payload;
    },
  },
  actions: {},
  getters: {},
  plugins: [vuexLocal.plugin],
});
