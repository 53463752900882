import { RouteConfig } from 'vue-router';

const baseRoute = 'contact/architects';

const routes: Array<RouteConfig> = [
  {
    path: `${baseRoute}/:id/detail`,
    component: () =>
      import(
        /* webpackChunkName: "architects-list" */ '@/views/contacts/architects/Detail.vue'
      ),
    children: [
      {
        name: 'Architect Contacts - Detail',
        path: '',
        component: () =>
          import(
            /* webpackChunkName: "architects-list" */ '@/components/moduleSpecific/architect/tabsDetail/Contacts.vue'
          ),
      },
      {
        name: 'Architect Contact Details - Detail',
        path: 'contact-details/:architectContactDetail',
        component: () =>
          import(
            /* webpackChunkName: "architects-list" */ '@/components/moduleSpecific/architect/tabsDetail/ContactDetails.vue'
          ),
      },
      {
        name: 'Architect Contact Persons - Detail',
        path: 'contact-persons/:architectContactPersons',
        component: () =>
          import(
            /* webpackChunkName: "architects-list" */ '@/components/moduleSpecific/architect/tabsDetail/ContactPersons.vue'
          ),
      },
      {
        name: 'Architect Other Details - Detail',
        path: 'other-details/:architectContactOtherDetail',
        component: () =>
          import(
            /* webpackChunkName: "architects-list" */ '@/components/moduleSpecific/architect/tabsDetail/OtherDetails.vue'
          ),
      },
    ],
  },
];

export default routes;
