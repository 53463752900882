import { RouteConfig } from 'vue-router';
import i18n from '@/i18n';

const routes: Array<RouteConfig> = [
  {
    path: 'personal/overtimeAccount',
    name: `${i18n.t('modules.overtimeAccount.name')}`,
  },
];

export default routes;
