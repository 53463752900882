import { RouteConfig } from 'vue-router';
import i18n from '@/i18n';

const baseRoute = 'finance';

const routes: Array<RouteConfig> = [
  {
    path: `${baseRoute}/open-claims`,
    name: `${i18n.t('modules.finances.openClaims.name')}`,
    component: () =>
      import(
        /* webpackChunkName: "openClaims" */ '@/views/finances/open-claims/Index.vue'
      ),
  },
  {
    path: `${baseRoute}/open-claims/:id`,
    name: `${i18n.t('modules.finances.openClaims.detail')}`,
    component: () =>
      import(
        /* webpackChunkName: "openClaims-detail" */ '@/views/finances/open-claims/DetailPage.vue'
      ),
  },
];

export default routes;
