import { RouteConfig } from 'vue-router';
import i18n from '@/i18n';

const baseRoute = 'contact/clients';

const routes: Array<RouteConfig> = [
  {
    name: `${i18n.t('modules.contact.clients.name')}`,
    path: baseRoute,
    component: () =>
      import(
        /* webpackChunkName: "clients" */ '@/views/contacts/clients/Index.vue'
      ),
  },
  {
    name: 'Client',
    path: `${baseRoute}/:id`,
    component: () =>
      import(
        /* webpackChunkName: "clients-edit" */ '@/views/contacts/clients/detail-client/Index.vue'
      ),
  },
];

export default routes;
