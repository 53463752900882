
import Vue from 'vue';
import LaptopEinsatzplanung from '../../assets/images/laptop-einsatzplanung.svg';
import IphoneFront from '../../assets/images/iphone-front.svg';
import AppStoreBadge from '../../assets/images/download-on-the-app-store-badge.svg';
import GooglePlayBadge from '../../assets/images/google-play-badge.svg';

export default Vue.extend({
  components: {
    LaptopEinsatzplanung,
    IphoneFront,
    AppStoreBadge,
    GooglePlayBadge,
  },
});
