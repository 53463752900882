import { RouteConfig } from 'vue-router';
import i18n from '@/i18n';
import userDetail from '@/router/personal/users/user-detail';
import userEdit from '@/router/personal/users/user-edit';

const baseRoute = 'personal/users';

const routes: Array<RouteConfig> = [
  {
    name: `${i18n.t('modules.employees.name')}`,
    path: baseRoute,
    component: () =>
      import(/* webpackChunkName: "users" */ '@/views/users/Index.vue'),
  },
  {
    name: 'Users Profile',
    path: 'profile/:userId/',
    component: () =>
      import(/* webpackChunkName: "users" */ '@/views/users/Profile.vue'),
  },
  ...userDetail,
  ...userEdit,
];

export default routes;
