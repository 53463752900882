import { RouteConfig } from 'vue-router';
import invoices from '@/router/finances/invoices';
import openClaims from '@/router/finances/open_claims';
import costs from '@/router/finances/costs';
import invoiceToIssue from '@/router/finances/invoice_to_issue';

const routes: Array<RouteConfig> = [
  ...invoices,
  ...openClaims,
  ...costs,
  ...invoiceToIssue,
];

export default routes;
