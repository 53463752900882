import { RouteConfig } from 'vue-router';
import i18n from '@/i18n';

const baseRoute = 'business';

const router: Array<RouteConfig> = [
  {
    path: `${baseRoute}/tasks`,
    name: `${i18n.t('modules.tasks.name')}`,
    component: () =>
      import(
        /* webpackChunkName: "tasks" */ '@/views/business/tasks/Index.vue'
      ),
  },
];

export default router;
