import { ActionContext } from 'vuex';
import { DetailClientContact } from '@/types/ClientContact';
import BaseService from '@/services/Base';
import { getDayName } from '@/utils/dom/helper';

export interface ClientModule {
  clientDetail: DetailClientContact;
}

const state = (): ClientModule => ({
  clientDetail: {} as DetailClientContact,
});

const mutations = {
  setClientDetail: (state: ClientModule, clientDetail: DetailClientContact) => {
    state.clientDetail = clientDetail;
    for (let i = 0; i < state.clientDetail.contactPersons.length; i += 1) {
      state.clientDetail.contactPersons[i].openingDayTo = getDayName(
        state.clientDetail.contactPersons[i].openingDayTo
      );
      state.clientDetail.contactPersons[i].openingDayFrom = getDayName(
        state.clientDetail.contactPersons[i].openingDayFrom
      );
    }
  },
};

const actions = {
  fetchClientDetail: async (
    { commit }: ActionContext<ClientModule, never>,
    id: number
  ) => {
    const service = new BaseService('/client-contact');

    try {
      const client = await service.getOne(id);

      commit('setClientDetail', client);
    } catch (e) {
      // TODO: nothing todo
      console.log(e);
    }
  },
};

export default {
  state,
  mutations,
  actions,
};
