import { TiptapVuetifyPlugin } from 'tiptap-vuetify';
import 'tiptap-vuetify/dist/main.css';
import 'typeface-quicksand/index.css';
import VueMask from 'v-mask';
import Vue from 'vue';
import VueFeather from 'vue-feather';
import VueSkycons from 'vue-skycons';
import Vuebar from 'vuebar';
import DatetimePicker from 'vuetify-datetime-picker';
import VueKeycloakJs from '@dsb-norge/vue-keycloak-js';
import App from './App.vue';
import i18n from './i18n';
import vuetify from './plugins/vuetify';
import router from './router';
import store from './store';

Vue.config.productionTip = false;
Vue.use(VueMask);
Vue.use(VueFeather);
Vue.use(VueSkycons, {
  color: '#1e88e5',
});
Vue.use(Vuebar);
Vue.use(DatetimePicker);
Vue.use(TiptapVuetifyPlugin, {
  vuetify,
  iconsGroup: 'mdi',
});

const setToken = (token) => {
  if (token !== '') {
    store.commit('setToken', token);
    localStorage.setItem('token', token);
  }
};

// Configure language from route
router.beforeEach((to, from, next) => {
  // Get lang param from route
  let language = to.params.lang;

  // Check if null
  if (!language) {
    language = 'de';
  }

  // Set current language
  i18n.locale = language;
  i18n.fallbackLocale = language;
  next();
});

Vue.use(VueKeycloakJs, {
  config: {
    url: process.env.VUE_APP_IDP_URL,
    clientId: process.env.VUE_APP_IDP_CLIENT_ID,
    realm: process.env.VUE_APP_IDP_REALM,
  },
  onReady(keycloak) {
    setToken(keycloak.token);
    keycloak
      .loadUserInfo()
      .then((userInfo: { [key: string]: any }) => {
        localStorage.setItem('userInfo', JSON.stringify(userInfo));
        store.commit('setAuthenticatedUser', userInfo);
        i18n.locale = userInfo.locale ? userInfo.locale : i18n.fallbackLocale;
      })
      .catch((e) => console.log(e));

    new Vue({
      el: '#app',
      i18n,
      router,
      store,
      vuetify,
      created() {
        this.$watch('$keycloak.token', setToken, { immediate: true });
      },
      template: '<App/>',
      render: (h) => h(App),
    }).$mount();
  },
});
