import { RouteConfig } from 'vue-router';
import i18n from '@/i18n';

const baseRoute = 'finance';

const routes: Array<RouteConfig> = [
  {
    path: `${baseRoute}/invoice-to-issue`,
    name: `${i18n.t('modules.finances.invoiceToIssue.name')}`,
    component: () =>
      import(
        /* webpackChunkName: "exports" */ '@/views/finances/invoice-to-issue/Index.vue'
      ),
  },
];

export default routes;
