import { RouteConfig } from 'vue-router';
import i18n from '@/i18n';

const baseRoute = 'mobile';

const router: Array<RouteConfig> = [
  {
    path: 'mobile',
    name: 'mobile',
    component: () =>
      import(/* webpackChunkName: "inventories" */ '@/views/mobile/index.vue'),
  },
];

export default router;
