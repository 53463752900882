
import Vue from 'vue';
import { mapGetters, mapMutations } from 'vuex';
import { Route } from 'vue-router';
import MobileView from './views/mobile/index.vue';

export default Vue.extend({
  name: 'App',
  components: {
    MobileView,
  },
  computed: {
    ...mapGetters(['token', 'snackbar']),
  },
  data: () => ({
    route: null as Route | null,
    windowDimensions: {
      height: window.innerHeight,
      width: window.innerWidth,
    },
  }),
  async created() {
    // await this.fetchFlags();
    this.route = this.$route;
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });

    const userbackScript = document.createElement('script');
    const userback = `
    window.Userback = window.Userback || {};
    Userback.access_token = '${process.env.VUE_APP_USERBACK_API_KEY}';
    (function(d) {
        var s = d.createElement('script');s.async = true;
        s.src = 'https://static.userback.io/widget/v1.js';
        (d.head || d.body).appendChild(s);
    })(document);
    `;
    const userbackCode = document.createTextNode(userback);
    userbackScript.appendChild(userbackCode);
    document.head.appendChild(userbackScript);

    const pendoScript = document.createElement('script');
    const pendo = `
    (function(apiKey){
    (function(p,e,n,d,o){var v,w,x,y,z;o=p[d]=p[d]||{};o._q=o._q||[];
    v=['initialize','identify','updateOptions','pageLoad','track'];for(w=0,x=v.length;w<x;++w)(function(m){
        o[m]=o[m]||function(){o._q[m===v[0]?'unshift':'push']([m].concat([].slice.call(arguments,0)));};})(v[w]);
        y=e.createElement(n);y.async=!0;y.src='https://cdn.pendo.io/agent/static/'+apiKey+'/pendo.js';
        z=e.getElementsByTagName(n)[0];z.parentNode.insertBefore(y,z);})(window,document,'script','pendo');

        // Call this whenever information about your visitors becomes available
        // Please use Strings, Numbers, or Bools for value types.
        pendo.initialize({
            visitor: {
                id:              'superuser'   // Required if user is logged in
            }
        });
    })('${process.env.VUE_APP_PENDO_API_KEY}');
    `;
    const pendoCode = document.createTextNode(pendo);
    pendoScript.appendChild(pendoCode);
    document.head.appendChild(pendoScript);
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    // ...mapActions(['fetchFlags']),
    ...mapMutations(['setSnackbar']),
    closeSnackbar() {
      const snackbar = {
        isVisible: false,
        message: '',
        color: '',
      };
      this.setSnackbar(snackbar);
    },
    onResize() {
      this.windowDimensions = {
        width: window.innerWidth,
        height: window.innerHeight,
      };
    },
  },
});
