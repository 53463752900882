import { RouteConfig } from 'vue-router';

const baseRoute = 'users';

const routes: Array<RouteConfig> = [
  {
    path: `${baseRoute}/:userId/edit`,
    component: () =>
      import(/* webpackChunkName: "users-edit" */ '@/views/users/Edit.vue'),
    children: [
      {
        name: 'Personal Information - Edit',
        path: '',
        component: () =>
          import(
            /* webpackChunkName: "users-edit" */ '@/components/moduleSpecific/employee/tabsEdit/PersonalInformation.vue'
          ),
      },
      {
        name: 'Employment - Edit',
        path: 'employment-edits',
        component: () =>
          import(
            /* webpackChunkName: "users-edit" */ '@/components/moduleSpecific/employee/tabsEdit/Employment.vue'
          ),
      },
      {
        name: 'Tax Data - Edit',
        path: 'tax-edits',
        component: () =>
          import(
            /* webpackChunkName: "users-edit" */ '@/components/moduleSpecific/employee/tabsEdit/TaxData.vue'
          ),
      },
      {
        name: 'Social Insurance - Edit',
        path: 'social-insurances-edits',
        component: () =>
          import(
            /* webpackChunkName: "users-edit" */ '@/components/moduleSpecific/employee/tabsEdit/SocialInsurance.vue'
          ),
      },
      {
        name: 'Document - Edit',
        path: 'documents/:documents',
        component: () =>
          import(
            /* webpackChunkName: "users-edit" */ '@/components/moduleSpecific/employee/tabsEdit/Document.vue'
          ),
      },
      {
        name: 'Authentication - Edit',
        path: 'authentication/:authentication',
        component: () =>
          import(
            /* webpackChunkName: "users-edit" */ '@/components/moduleSpecific/employee/tabsEdit/Authentication.vue'
          ),
      },
      {
        name: 'Permissions - Edit',
        path: 'permissions/:permissions',
        component: () =>
          import(
            /* webpackChunkName: "users-edit" */ '@/components/moduleSpecific/employee/tabsEdit/Permissions.vue'
          ),
      },
      {
        name: 'Dashboard - Edit',
        path: 'dashboard',
        component: () =>
          import(
            /* webpackChunkName: "users-edit" */ '@/components/moduleSpecific/employee/tabsEdit/Dashboard.vue'
          ),
      },
      {
        name: 'Others - Edit',
        path: 'others',
        component: () =>
          import(
            /* webpackChunkName: "users-edit" */ '@/components/moduleSpecific/employee/tabsEdit/Others.vue'
          ),
      },
    ],
  },
];

export default routes;
