import { RouteConfig } from 'vue-router';
import i18n from '@/i18n';
import supplierEdit from '@/router/contacts/suppliers/supplier-edit';

const baseRoute = 'contact/suppliers';

const routes: Array<RouteConfig> = [
  {
    name: `${i18n.t('modules.contact.supplier.name')}`,
    path: baseRoute,
    component: () =>
      import(
        /* webpackChunkName: "suppliers" */ '@/views/contacts/supplier/Index.vue'
      ),
  },
  ...supplierEdit,
];

export default routes;
