import { RouteConfig } from 'vue-router';

const baseRoute = 'contact/architects';

const routes: Array<RouteConfig> = [
  {
    path: `${baseRoute}/:id/edit`,
    component: () =>
      import(
        /* webpackChunkName: "architects-edit" */ '@/views/contacts/architects/Edit.vue'
      ),
    children: [
      {
        name: 'Architect Contacts - Edit',
        path: '',
        component: () =>
          import(
            /* webpackChunkName: "architects-edit" */ '@/components/moduleSpecific/architect/tabsEdit/Contacts.vue'
          ),
      },
      {
        name: 'Architect Contact Details - Edit',
        path: 'contact-details/:architectContactDetail',
        component: () =>
          import(
            /* webpackChunkName: "architects-edit" */ '@/components/moduleSpecific/architect/tabsEdit/ContactDetails.vue'
          ),
      },
      {
        name: 'Architect Contact Persons - Edit',
        path: 'contact-persons/:architectContactPersons',
        component: () =>
          import(
            /* webpackChunkName: "architects-edit" */ '@/components/moduleSpecific/architect/tabsEdit/ContactPersons.vue'
          ),
      },
      {
        name: 'Architect Other Details - Edit',
        path: 'other-details/:architectContactOtherDetail',
        component: () =>
          import(
            /* webpackChunkName: "architects-edit" */ '@/components/moduleSpecific/architect/tabsEdit/OtherDetails.vue'
          ),
      },
    ],
  },
];

export default routes;
