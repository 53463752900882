import { RouteConfig } from 'vue-router';
import i18n from '@/i18n';

const routes: Array<RouteConfig> = [
  {
    path: 'personal/working-hours',
    name: `${i18n.t('modules.workingHours.name')}`,
    component: () =>
      import(
        /* webpackChunkName: "workingHours" */ '@/views/working-hours/Index.vue'
      ),
  },
  {
    path: 'personal/working-hours/:tab',
    name: `${i18n.t('modules.workingHours.detail')}`,
    component: () =>
      import(
        /* webpackChunkName: "workingHourChildren" */ '@/views/working-hours/Index.vue'
      ),
  },
];

export default routes;
