import { RouteConfig } from 'vue-router';
import users from '@/router/personal/users/index';
import workingHours from '@/router/personal/workingHours/index';
import exports from '@/router/personal/exports/index';
import overtimeAccounts from '@/router/personal/overtimeAccounts/index';

const routes: Array<RouteConfig> = [
  ...users,
  ...workingHours,
  ...exports,
  ...overtimeAccounts,
];

export default routes;
