import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import i18n from '@/i18n';
import business from '@/router/business/index';
import contacts from '@/router/contacts/index';
import finances from '@/router/finances/index';
import personal from '@/router/personal/index';
import settings from '@/router/settings/index';
import dashboard from '@/router/dashboards/index';
import mobile from '@/router/mobile/index';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    redirect: `/${i18n.locale}/dashboard/cockpit`,
  },
  {
    path: '/:lang/',
    component: {
      render: (h) => h('router-view'),
    },
    children: [
      {
        path: '/',
        name: 'Home',
        component: () =>
          import(
            /* webpackChunkName: "index" */ '@/components/organisms/layouts/full-layout/Layout.vue'
          ),
        children: [
          ...contacts,
          ...finances,
          ...business,
          ...settings,
          ...personal,
          ...dashboard,
        ],
      },
      ...mobile,
      {
        path: '/preview-pdf/:id',
        name: 'PDF Preview',
        component: () =>
          import(
            /* webpackChunkName: "offers-list" */ '@/views/Preview/PreviewPdf.vue'
          ),
      },
      {
        path: `/${i18n.locale}/not-allowed`,
        component: () =>
          import(
            /* webpackChunkName: "not-allowed" */ '@/views/NotAllowed.vue'
          ),
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
