import { RouteConfig } from 'vue-router';
import i18n from '@/i18n';

// @ts-ignore
const router: Array<RouteConfig> = [
  {
    path: 'dashboard/cockpit',
    name: `${i18n.t('modules.dashboard.digitalGlassboard.name')}`,
    component: () =>
      import(
        /* webpackChunkName: "dashboard" */ '@/views/dashboards/cockpit/Index.vue'
      ),
  },
  {
    path: 'dashboard/my-tasks',
    name: `${i18n.t('modules.dashboard.myTasks.title')}`,
    component: () =>
      import(
        /* webpackChunkName: "dashboard" */ '@/views/dashboards/my-tasks/Index.vue'
      ),
  },
];

export default router;
