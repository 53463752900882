import { ModuleName } from '@/constants/searchModuleName';
// eslint-disable-next-line import/no-cycle
import BaseService from '@/services/Base';

type fetchItemPayload = {
  value: any;
  type: ModuleName;
};

const data = () => ({
  searchTerm: '',
  searchItems: [] as any[],
  currentApi: '',
});

const getters = {
  searchTerm: (state) => state.searchTerm,
  searchItems: (state) => state.searchItems,
};

const actions = {
  resetSearch: async ({ commit }) => {
    commit('setDefault');
  },
  search: async ({ commit }, value) => {
    commit('setSearchTerm', value);
  },
  sendCurrentApi: async ({ commit }, url) => {
    commit('setCurrentApi', url);
  },
  fetchSearchItems: async (
    { commit, state },
    { value, type }: fetchItemPayload
  ) => {
    const service = new BaseService(`${state.currentApi}`);
    let res;
    switch (type) {
      case ModuleName.PROJECT_TASK:
        if (value.constructionId === 0) {
          res = await service.get(`searchTerm=${value.search}`);
        } else {
          res = await service.get(
            `searchTerm=${value.search}&search=constructionSite.id=${value.constructionId}`
          );
        }
        break;
      case ModuleName.WORKING_HOURS_CONSTRUCTION:
        res = await service.get(
          `category=CONSTRUCTION_SITE&searchTerm=${value}`
        );
        break;
      case ModuleName.WORKING_HOURS_CONSTRUCTION_OPEN:
        res = await service.get(
          `category=CONSTRUCTION_SITE&status=OPEN&searchTerm=${value}`
        );
        break;
      case ModuleName.WORKING_HOURS_CONSTRUCTION_ACCEPTED:
        res = await service.get(
          `category=CONSTRUCTION_SITE&status=ACCEPTED&searchTerm=${value}`
        );
        break;
      case ModuleName.WORKING_HOURS_CONSTRUCTION_REJECTED:
        res = await service.get(
          `category=CONSTRUCTION_SITE&status=REJECTED&searchTerm=${value}`
        );
        break;
      case ModuleName.WORKING_HOURS_EDUCATION:
        res = await service.get(`category=EDUCATION&searchTerm=${value}`);
        break;
      case ModuleName.WORKING_HOURS_EDUCATION_OPEN:
        res = await service.get(
          `category=EDUCATION&status=OPEN&searchTerm=${value}`
        );
        break;
      case ModuleName.WORKING_HOURS_EDUCATION_ACCEPTED:
        res = await service.get(
          `category=EDUCATION&status=ACCEPTED&searchTerm=${value}`
        );
        break;
      case ModuleName.WORKING_HOURS_EDUCATION_REJECTED:
        res = await service.get(
          `category=EDUCATION&status=REJECTED&searchTerm=${value}`
        );
        break;
      case ModuleName.WORKING_HOURS_OFFICE:
        res = await service.get(`category=OFFICE_WORK&searchTerm=${value}`);
        break;
      case ModuleName.WORKING_HOURS_OFFICE_OPEN:
        res = await service.get(`category=&status=OPEN&searchTerm=${value}`);
        break;
      case ModuleName.WORKING_HOURS_OFFICE_ACCEPTED:
        res = await service.get(
          `category=&status=ACCEPTED&searchTerm=${value}`
        );
        break;
      case ModuleName.WORKING_HOURS_OFFICE_REJECTED:
        res = await service.get(
          `category=&status=REJECTED&searchTerm=${value}`
        );
        break;
      case ModuleName.WORKING_HOURS_SICK_OPEN:
        res = await service.get(
          `category=SICK_LEAVE&status=OPEN&searchTerm=${value}`
        );
        break;
      case ModuleName.WORKING_HOURS_SICK_ACCEPTED:
        res = await service.get(
          `category=SICK_LEAVE&status=ACCEPTED&searchTerm=${value}`
        );
        break;
      case ModuleName.WORKING_HOURS_SICK_REJECTED:
        res = await service.get(
          `category=SICK_LEAVE&status=REJECTED&searchTerm=${value}`
        );
        break;
      case ModuleName.WORKING_HOURS_VACATION_OPEN:
        res = await service.get(
          `category=VACATION_LEAVE&status=OPEN&searchTerm=${value}`
        );
        break;
      case ModuleName.WORKING_HOURS_VACATION_ACCEPTED:
        res = await service.get(
          `category=VACATION_LEAVE&status=ACCEPTED&searchTerm=${value}`
        );
        break;
      case ModuleName.WORKING_HOURS_VACATION_REJECTED:
        res = await service.get(
          `category=VACATION_LEAVE&status=REJECTED&searchTerm=${value}`
        );
        break;
      case ModuleName.EMPLOYEE_CONSTRUCTION_WORKER:
        res = await service.get(
          `searchTerm=${value}&search=employmentDetail.workersType.workersType:*Construction*`
        );
        break;
      case ModuleName.EMPLOYEE_OFFICE_WORKER:
        res = await service.get(
          `searchTerm=${value}&search=employmentDetail.workersType.workersType:*Office*`
        );
        break;
      case ModuleName.CLIENT_PERSONAL:
        res = await service.get(
          `searchTerm=${value}&search=clientType.clientType:"Personal" AND`
        );
        break;
      case ModuleName.CLIENT_COMPANY:
        res = await service.get(
          `searchTerm=${value}&search=clientType.clientType:"Company" AND`
        );
        break;
      case ModuleName.PROJECT_OFFER_OPEN:
        res = await service.get(
          `searchTerm=${value}&search=statusOffer.statusOffer:'Open'`
        );
        break;
      case ModuleName.PROJECT_OFFER_OVERDUE:
        res = await service.get(
          `searchTerm=${value}&search=statusOffer.statusOffer:'Overdue'`
        );
        break;
      case ModuleName.PROJECT_OFFER_ACCEPTED:
        res = await service.get(
          `searchTerm=${value}&search=statusOffer.statusOffer:'Accepted'`
        );
        break;
      case ModuleName.PROJECT_OFFER_DECLINED:
        res = await service.get(
          `searchTerm=${value}&search=statusOffer.statusOffer:'Declined'`
        );
        break;
      case ModuleName.PROJECT_ASSIGNMENT_ALL:
        res = await service.get(
          `searchTerm=${value}&search=statusConstruction.statusConstruction!null`
        );
        break;
      case ModuleName.PROJECT_ASSIGNMENT_EXECUTION:
        res = await service.get(
          `searchTerm=${value}&search=statusConstruction.statusConstruction:'In Execution'`
        );
        break;
      case ModuleName.PROJECT_ASSIGNMENT_FINISHED:
        res = await service.get(
          `searchTerm=${value}&search=statusConstruction.statusConstruction:'Finished'`
        );
        break;
      case ModuleName.PROJECT_PRE_PERFORMANCE_EXECUTION:
        res = await service.get(`searchTerm=${value}&status=In execution`);
        break;
      case ModuleName.PROJECT_PRE_PERFORMANCE_FINISHED:
        res = await service.get(`searchTerm=${value}&status=Finished`);
        break;
      case ModuleName.FINANCE_COST_STAFF:
        res = await service.get(`costType=STAFF&searchTerm=${value}`);
        break;
      case ModuleName.FINANCE_COST_MATERIAL:
        res = await service.get(`costType=MATERIAL&searchTerm=${value}`);
        break;
      case ModuleName.FINANCE_COST_SUBCONTRACTOR:
        res = await service.get(`costType=SUBCONTRACTOR&searchTerm=${value}`);
        break;
      case ModuleName.FINANCE_COST_LOAN:
        res = await service.get(
          `search=statusConstruction.statusConstruction!=null&searchTerm=${value}`
        );
        break;
      case ModuleName.FINANCE_INCOMING_INVOICE_RELEASED:
        res = await service.get(`status=To Be Released&searchTerm=${value}`);
        break;
      case ModuleName.FINANCE_INCOMING_INVOICE_APPROVED:
        res = await service.get(`status=Approved&searchTerm=${value}`);
        break;
      case ModuleName.FINANCE_INCOMING_INVOICE_PAID:
        res = await service.get(`status=Paid&searchTerm=${value}`);
        break;
      case ModuleName.FINANCE_OUTGOING_INVOICE:
        res = await service.get(
          `search=claimType.claimType:claim&searchTerm=${value}`
        );
        break;
      case ModuleName.FINANCE_OUTGOING_VALUE_CORRECTION:
        res = await service.get(
          `search=claimType.claimType:valueCorrection&searchTerm=${value}`
        );
        break;
      case ModuleName.FINANCE_INVOICE_TO_ISSUE:
        res = await service.get(`searchTerm=${value}`);
        break;
      default:
        res = await service.get(`searchTerm=${value}`);
        break;
    }

    commit('setSearchItems', res);
  },
};

const mutations = {
  setDefault: (state) => {
    state.searchTerm = '';
    state.searchItems = [] as any[];
  },
  setSearchTerm: (state, searchTerm) => {
    state.searchTerm = searchTerm;
  },
  setCurrentApi: (state, currentApi) => {
    state.currentApi = currentApi;
  },
  setSearchItems: (state, searchItems) => {
    state.searchItems = searchItems;
  },
};

export default {
  state: data,
  getters,
  actions,
  mutations,
};
